export function cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function mergeObjects(obj1, obj2) {
    const merged = {}

    if (obj1) {
        for (let prop in obj1) {
            if (obj1[prop] !== null) {
                merged[prop] = obj1[prop]
            }
        }
    }

    if (obj2) {
        for (let prop in obj2) {
            if (obj2[prop] !== null) {
                merged[prop] = obj2[prop]
            }
        }
    }

    return merged
}

/**
 * Traverse an object using a full field name
 * @param fullFieldName the full field name (e.g. "address.street")
 * @param object the object to traverse
 * @returns the value of the field or null if the field does not exist
 */
export function traverseObject(fullFieldName, object) {
    let parts = fullFieldName.split(".")
    let result = object
    parts.forEach(field => {
        if (result) {
            result = result[field]
        }
    })
    return result
}

/**
 * Traverse a field definition using a full field name
 * @param fullFieldName the full field name (e.g. "address.street")
 * @param fieldDefinition the field definition to traverse
 * @returns the field definition of the field or null
 */
export function traverseFieldDefinition(fullFieldName, fieldDefinition) {
    let parts = fullFieldName.split(".")
    let result = fieldDefinition
    parts.forEach(field => {
        if (result) {
            if (result.fields) {
                result = result.fields[field]
            } else {
                result = result[field]
            }
        }
    })
    return result
}
