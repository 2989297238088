import {useTranslation} from "react-i18next";
import ProjectWarnings from "../project/ProjectWarnings";
import CheckboxesInternalListLocal from "./CheckboxesInternalListLocal";
import RadioButtonInternalListLocal from "./RadioButtonInternalListLocal";
import SearchInternalListLocal from "./SearchInternalListLocal";
import React from "react";
import DelayedInput from "./DelayedInput";
import "./AutoFormField.css"
import CheckboxBoolean from "./CheckboxBoolean";
import RadioButtonYesNo from "./RadioButtonYesNo";
import RadioButtonYesNoNone from "./RadioButtonYesNoNone";

/**
 * Automatic display and update.
 * - fields : The fields of the form (Map<String, AutoFormFieldDefinition>)
 * - fieldName : The name for the input
 * - fullFieldName : The name for the input
 * - labelId : The label id
 * - hideMainLabel : Hide the main label
 * - project : The project
 * - parentField : The parent field
 * - warningPrefix : The warning prefix
 * - listByType : The list of internal list by type
 * - onChangeSelectedItem : A callback function(fieldDefinition, fieldFullName, newValue)
 * - onAddToMany : A callback function(fieldDefinition, fieldFullName)
 * - onRemoveFromMany : A callback function(fieldDefinition, fieldFullName, index)
 */
function AutoFormField(props) {

    const {t, i18n} = useTranslation()

    const ready = props.listByType && props.project && props.fields
    if (!ready) {
        return <></>
    }
    const fullFieldName = props.fullFieldName ? props.fullFieldName : props.fieldName
    const parentField = props.parentField ? props.parentField : props.project
    const fieldDefinition = props.fields[props.fieldName]
    if (!fieldDefinition) {
        return <>PROBLEM {fullFieldName}<br/></>
    }

    let itemField = parentField[props.fieldName]
    let warningPrefix = props.warningPrefix ? props.warningPrefix : ''
    if (fieldDefinition.warningPrefix) {
        warningPrefix += fieldDefinition.warningPrefix
    }

    // Check conditional met
    const conditional = fieldDefinition.conditional
    if (conditional) {
        const conditionalFieldName = conditional.fieldName
        const conditionalFieldValue = parentField[conditionalFieldName]
        const conditionalFieldValueType = props.fields[conditionalFieldName].classField.type
        const expectFilled = conditional.filled
        let conditionalFieldIsNotFilled = conditionalFieldValue === null || conditionalFieldValue === undefined
        if (!conditionalFieldIsNotFilled) {
            if (conditionalFieldValueType === 'boolean') {
                conditionalFieldIsNotFilled = !conditionalFieldValue
            }
        }

        const display = conditionalFieldIsNotFilled !== expectFilled
        if (!display) {
            return <></>
        }
    }

    // Check if dependencies met
    const dependFeatures = fieldDefinition.dependFeatures
    if (dependFeatures) {
        let display = true

        for (let dependFeature of dependFeatures) {

            // Get the depended field from the project or the parent object
            let dependFeatureFieldName = dependFeature.fieldName
            let dependFieldValue = parentField[dependFeatureFieldName]

            if (dependFieldValue) {

                // Get the depended field definition
                var dependedFieldDefinition = props.fields[dependFeatureFieldName]
                if (dependedFieldDefinition) {
                    let internalListType = null
                    let internalListId = null
                    if (dependFeature.precisionId) {
                        internalListType = dependedFieldDefinition.internalListWithPrecision.precisionType
                        internalListId = dependFieldValue.precisionId
                    } else {
                        // If dependFieldValue is a string, then it is the internal list id
                        internalListType = dependedFieldDefinition.internalList?.type || dependedFieldDefinition.internalListWithPrecision?.type
                        if (typeof dependFieldValue === 'string') {
                            internalListId = dependFieldValue
                        } else {
                            internalListId = dependFieldValue.id
                        }
                    }

                    if (internalListType != null && internalListId != null) {
                        const dependedInternalList = props.listByType[internalListType].find(l => l.id === internalListId)
                        if (dependedInternalList != null) {
                            const features = dependedInternalList.features
                            if (!features.includes(dependFeature.feature)) {
                                display = false
                            }
                        }
                    } else {
                        display = false
                    }
                } else {
                    display = false
                }
            } else {
                display = false
            }

        }

        if (!display) {
            return <></>
        }
    }

    const manipulatedChildren = []
    if (React.Children) {
        if (fieldDefinition.hasMany) {
            if (!itemField) {
                itemField = []
            }

            // Go through all the items and clone all the children
            itemField.forEach((item, index) => {
                manipulatedChildren.push(<div className="child">
                    <p>{index + 1}
                        <button className={'btn btn-danger float-end'} type="button" onClick={() => removeChild(index)}>
                            -
                        </button>
                    </p>
                    {React.Children.map(props.children, child => {
                        return React.cloneElement(child, {
                            fullFieldName: `${fullFieldName}[${index}].${child.props.fieldName}`,
                            fields: props.fields[props.fieldName].fields,
                            parentField: item,
                            warningPrefix: `${warningPrefix}${props.fieldName}[${index}].`,
                        })
                    })}
                </div>)
            })

            manipulatedChildren.push(
                <button className={'btn btn-success'} type="button" onClick={addChild}>
                    +
                </button>
            )
        } else {
            React.Children.forEach(props.children, child => {
                manipulatedChildren.push(React.cloneElement(child, {
                    fullFieldName: `${fullFieldName}.${child.props.fieldName}`,
                    fields: props.fields[props.fieldName].fields,
                    parentField: itemField,
                    warningPrefix: `${warningPrefix}${props.fieldName}.`,
                }))
            })
        }
    }

    function addChild() {
        return props.onAddToMany(fieldDefinition, fullFieldName)
    }

    function removeChild(index) {
        return props.onRemoveFromMany(fieldDefinition, fullFieldName, index)
    }

    function itemChangeForInternalList(newItem) {
        return props.onChangeSelectedItem(fieldDefinition, fullFieldName, newItem?.id)
    }

    function itemsChangeForInternalList(newItems) {
        const ids = newItems.map(item => item.id)
        return props.onChangeSelectedItem(fieldDefinition, fullFieldName, ids)
    }

    function itemChangeForText(newValue) {
        return props.onChangeSelectedItem(fieldDefinition, fullFieldName, newValue)
    }

    function itemChangeForInternalListWithPrecisionTheId(newItem) {
        return props.onChangeSelectedItem(fieldDefinition, fullFieldName, {
            id: newItem?.id,
        })
    }

    function itemChangeForInternalListWithPrecisionThePrecision(newItem) {
        return props.onChangeSelectedItem(fieldDefinition, fullFieldName, {
            id: itemField?.id,
            precisionId: newItem?.id,
        })
    }

    return (<>

        {props.labelId && !props.hideMainLabel && <strong>{t(props.labelId)}</strong>}

        {/* Internal list */}
        {fieldDefinition.internalList && <>

            {fieldDefinition.internalList.multiple && <>
                {fieldDefinition.internalList.display === 'CHECKBOX' &&
                    <CheckboxesInternalListLocal
                        listByType={props.listByType}
                        name={fullFieldName}
                        type={fieldDefinition.internalList.type}
                        selectedIds={itemField}
                        onChangeSelectedItems={(newItem) => itemsChangeForInternalList(newItem)}
                    />
                }
            </>}

            {!fieldDefinition.internalList.multiple && <>
                {fieldDefinition.internalList.display === 'RADIO' &&
                    <RadioButtonInternalListLocal
                        listByType={props.listByType}
                        name={fullFieldName}
                        type={fieldDefinition.internalList.type}
                        selectedId={itemField}
                        onChangeSelectedItem={(newItem) => itemChangeForInternalList(newItem)}
                    />
                }

                {fieldDefinition.internalList.display === 'SEARCH' &&
                    <SearchInternalListLocal
                        listByType={props.listByType}
                        name={fullFieldName}
                        type={fieldDefinition.internalList.type}
                        selectedId={itemField}
                        onChangeSelectedItem={(newItem) => itemChangeForInternalList(newItem)}
                    />
                }
            </>}
        </>}

        {/* Internal list with precision */}
        {fieldDefinition.internalListWithPrecision && <>

            {fieldDefinition.internalListWithPrecision.display === 'RADIO' &&
                <RadioButtonInternalListLocal
                    listByType={props.listByType}
                    name={fullFieldName}
                    type={fieldDefinition.internalListWithPrecision.type}
                    selectedId={itemField?.id}
                    onChangeSelectedItem={(newItem) => itemChangeForInternalListWithPrecisionTheId(newItem)}
                />
            }

            {fieldDefinition.internalListWithPrecision.display === 'SEARCH' &&
                <SearchInternalListLocal
                    listByType={props.listByType}
                    name={fullFieldName}
                    type={fieldDefinition.internalListWithPrecision.type}
                    selectedId={itemField?.id}
                    onChangeSelectedItem={(newItem) => itemChangeForInternalListWithPrecisionTheId(newItem)}
                />
            }

            {props.listByType[fieldDefinition.internalListWithPrecision.precisionType].filter(p => p.dependOnId === itemField?.id).length > 0 &&
                <div className="col-12 col-sm-6">
                    <strong>{t('project.finding.precisionId')}</strong>
                    <br/>
                    {fieldDefinition.internalListWithPrecision.precisionDisplay === 'RADIO' &&
                        <RadioButtonInternalListLocal
                            listByType={props.listByType}
                            name={`${fullFieldName}-precision`}
                            type={fieldDefinition.internalListWithPrecision.precisionType}
                            selectedId={itemField?.precisionId}
                            dependOnId={itemField?.id}
                            onChangeSelectedItem={(newItem) => itemChangeForInternalListWithPrecisionThePrecision(newItem)}
                        />
                    }
                    {fieldDefinition.internalListWithPrecision.precisionDisplay === 'SEARCH' &&
                        <SearchInternalListLocal
                            listByType={props.listByType}
                            name={`${fullFieldName}-precision`}
                            type={fieldDefinition.internalListWithPrecision.precisionType}
                            selectedId={itemField?.precisionId}
                            dependOnId={itemField?.id}
                            onChangeSelectedItem={(newItem) => itemChangeForInternalListWithPrecisionThePrecision(newItem)}
                        />
                    }
                </div>
            }

        </>}

        {/* Other type */}
        {fieldDefinition.classField && <>
            {fieldDefinition.classField.type === 'boolean' && <>
                {fieldDefinition.classField.display === 'CHECKBOX' &&
                    <CheckboxBoolean
                        label={t(props.labelId)}
                        name={fullFieldName}
                        value={itemField}
                        onChangeValue={itemChangeForText}
                    />
                }
                {fieldDefinition.classField.display === 'YESNO' &&
                    <RadioButtonYesNo
                        name={fullFieldName}
                        value={itemField}
                        onChangeValue={itemChangeForText}
                    />
                }
            </>}
            {fieldDefinition.classField.type === 'java.lang.Boolean' && <>
                {fieldDefinition.classField.display === 'CHECKBOX' &&
                    <CheckboxBoolean
                        label={t(props.labelId)}
                        name={fullFieldName}
                        value={itemField}
                        onChangeValue={itemChangeForText}
                    />
                }
                {fieldDefinition.classField.display === 'YESNO' &&
                    <RadioButtonYesNoNone
                        name={fullFieldName}
                        value={itemField}
                        onChangeValue={itemChangeForText}
                    />
                }
            </>}
            {fieldDefinition.classField.type === 'java.lang.Integer' &&
                <DelayedInput
                    type="number" className="form-control"
                    name={fullFieldName}
                    initialValue={itemField}
                    waitTimeInMs={1000}
                    onChange={itemChangeForText}
                />
            }
            {fieldDefinition.classField.type === 'java.lang.String' &&
                <DelayedInput
                    type="text" className="form-control"
                    name={fullFieldName}
                    initialValue={itemField}
                    waitTimeInMs={1000}
                    onChange={itemChangeForText}
                />
            }
            {fieldDefinition.classField.type === 'java.time.LocalDate' &&
                <DelayedInput
                    type="date" className="form-control"
                    name={fullFieldName}
                    initialValue={itemField}
                    waitTimeInMs={1000}
                    onChange={itemChangeForText}
                />
            }
            {/*{fieldDefinition.classField.type === 'java.util.Date' &&*/}
            {/*    <DelayedInput*/}
            {/*        type="date" className="form-control"*/}
            {/*        name={fieldName}*/}
            {/*        initialValue={itemField}*/}
            {/*        waitTimeInMs={1000}*/}
            {/*        onChange={itemChangeForText}*/}
            {/*    />*/}
            {/*}*/}

        </>}

        <ProjectWarnings project={props.project} fieldName={`${warningPrefix}${props.fieldName}`}/>

        {manipulatedChildren}
    </>)
}

export default AutoFormField
