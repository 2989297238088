import React from "react";
import AutoFormField from "../common/AutoFormField";

/**
 * An electric distribution panel
 *
 * - fields : The fields of the form (Map<String, AutoFormFieldDefinition>)
 * - fieldName : The name for the input
 * - project : The project
 * - listByType : The list by type
 * - onChangeSelectedItem : A callback function(fieldDefinition, fieldFullName, newValue)
 * - onAddToMany : A callback function(fieldDefinition, fieldFullName)
 * - onRemoveFromMany : A callback function(fieldDefinition, fieldFullName, index)
 */
function ElectricDistributionPanel(props) {

    return (
        <AutoFormField
            fields={props.fields}
            fieldName={props.fieldName}
            project={props.project}
            listByType={props.listByType}
            onAddToMany={props.onAddToMany}
            onRemoveFromMany={props.onRemoveFromMany}
        >
            <AutoFormField
                fields={props.fields}
                fieldName="typeId"
                labelId="projectBuildingElectricDistributionPanel.typeId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="typeOther"
                labelId="projectBuildingElectricDistributionPanel.typeOther"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="manufacturerId"
                labelId="projectBuildingElectricDistributionPanel.manufacturerId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="manufacturerUnidentifiedMotiveId"
                labelId="projectBuildingElectricDistributionPanel.manufacturerUnidentifiedMotiveId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="manufacturerUnidentifiedMotiveOther"
                labelId="projectBuildingElectricDistributionPanel.manufacturerUnidentifiedMotiveOther"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="manufacturerOther"
                labelId="projectBuildingElectricDistributionPanel.manufacturerOther"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="currentIntensityId"
                labelId="projectBuildingElectricDistributionPanel.currentIntensityId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="currentIntensityUnidentifiedMotiveId"
                labelId="projectBuildingElectricDistributionPanel.currentIntensityUnidentifiedMotiveId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="currentIntensityUnidentifiedMotiveOther"
                labelId="projectBuildingElectricDistributionPanel.currentIntensityUnidentifiedMotiveOther"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="currentIntensityOther"
                labelId="projectBuildingElectricDistributionPanel.currentIntensityOther"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="phaseTypeId"
                labelId="projectBuildingElectricDistributionPanel.phaseTypeId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="phaseTypeOther"
                labelId="projectBuildingElectricDistributionPanel.phaseTypeOther"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="polesAmountId"
                labelId="projectBuildingElectricDistributionPanel.polesAmountId"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="spaceAmount"
                labelId="projectBuildingElectricDistributionPanel.spaceAmount"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="maxBranchCircuitsAmount"
                labelId="projectBuildingElectricDistributionPanel.maxBranchCircuitsAmount"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
            <AutoFormField
                fields={props.fields}
                fieldName="availableBranchCircuitsAmount"
                labelId="projectBuildingElectricDistributionPanel.availableBranchCircuitsAmount"
                project={props.project}
                listByType={props.listByType}
                onChangeSelectedItem={props.onChangeSelectedItem}
            />
        </AutoFormField>
    )
}

export default ElectricDistributionPanel
